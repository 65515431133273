import React from "react";

export function Logos() {
  return (
    <div className="absolute top-0 right-0 ma3 z-1">
      <img
        src={require("./logo-chateau-thierry.png")}
        className="bg-white mr2"
        style={{ height: "90px" }}
      />
      <img src={require("./logo-prefet.jpg")} style={{ height: "90px" }} />
    </div>
  );
}
