import React from "react";

export const Loader = ({ loading }) => {
  return loading ? (
    <div id="loader">
      <h1>Le Chasteau de Thiery</h1>
      <h3 style={{ color: "#BB895D" }}>1500 ans d’histoire d’un château</h3>
    </div>
  ) : null;
};
