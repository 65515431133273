import React, { useContext, useState } from "react";

export const CameraContext = React.createContext({
  camera: null,
  setCamera: () => ({})
});

export function CameraProvider(props) {
  const [camera, setCamera] = useState(null);

  return (
    <CameraContext.Provider
      value={{
        camera,
        setCamera
      }}
    >
      {props.children}
    </CameraContext.Provider>
  );
}

export function withCamera(Component) {
  return function WrappedComponent({ children, ...props }) {
    const { camera } = useContext(CameraContext);

    return (
      <Component {...props} camera={camera}>
        {children}
      </Component>
    );
  };
}
