import React from "react";

export const PanelContainer = props => (
  <div
    className={`w-20 vh-100 overflow-scroll ${props.className || ""}`}
    style={{ backgroundColor: "#EBD3AA" }}
  >
    {props.children}
  </div>
);

const BackButton = props => (
  <a
    href="#"
    className="fa fa-chevron-left black link pv2 ph3"
    onClick={e => {
      e.preventDefault();
      props.onClick();
    }}
  />
);

export const NavBar = props => (
  <div
    className="flex b--gray b--solid pv3 secondary f4"
    style={{ borderWidth: "0 0 1px 0" }}
  >
    <div className="ma0 w-100">
      <BackButton
        onClick={() => {
          props.goBack();
        }}
      />
      {props.title}
    </div>
  </div>
);
