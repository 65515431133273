import React from "react";
import ReactDOM from "react-dom";

import { Loader } from "./components/Loader";
import { Container } from "./components/Container";
import { Logos } from "./components/Logos";
import { Castle, INITIAL_POSITION } from "./Main/Castle";
import { Panel } from "./Main/Panel";
import { CameraProvider, withCamera } from "./Main/CameraContext";
import { CameraDebug } from "./Main/Castle/CameraDebug";

import "tachyons";

const HomeFab = withCamera((props) => (
  <button
    className="fa fa-home absolute bottom-0 right-0 bg-light-gray br-100 f2 ma3 pa3"
    onClick={() => props.camera.position.set(...INITIAL_POSITION)}
  />
));

const WaitCameraReady = withCamera((props) => (
  <Loader loading={!props.camera} />
));

function App() {
  return (
    <CameraProvider>
      <WaitCameraReady />
      <Logos />
      {process.env.NODE_ENV === "development" && <CameraDebug />}
      <Container>
        <Panel />
        <Castle />
      </Container>
      <HomeFab />
    </CameraProvider>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
