var THREE = require("three");
var OrbitControls = require("three-orbit-controls")(THREE);

import { loadGlobal } from "./scenes";

export const INITIAL_POSITION = [57.15, 11.03, -188.14];

function initControls(renderer) {
  const { width, height } = renderer.getSize();
  const camera = new THREE.PerspectiveCamera(60, width / height, 1, 3000);

  const controls = new OrbitControls(camera, renderer.domElement);
  controls.enableDamping = false; // an animation loop is required when either damping or auto-rotation are enabled
  controls.rotateSpeed = 0.25; // Default is 1
  controls.zoomSpeed = 0.25; // Default is 1
  controls.panningMode = THREE.HorizontalPanning; // default is THREE.ScreenSpacePanning, other value is THREE.HorizontalPanning
  controls.minDistance = 50;
  controls.maxDistance = 500;
  controls.maxPolarAngle = Math.PI / 2;

  return { camera, controls };
}

function initRenderer(container) {
  const renderer = new THREE.WebGLRenderer();
  renderer.setPixelRatio(window.devicePixelRatio);

  renderer.setSize(container.clientWidth, container.clientHeight);

  return renderer;
}

function createOnWindowResize(container, camera, renderer) {
  return function() {
    camera.aspect = container.clientWidth / container.clientHeight;
    camera.updateProjectionMatrix();

    renderer.setSize(container.clientWidth, container.clientHeight);
  };
}

export function init(container, onLoadingFinished = () => {}) {
  const renderer = initRenderer(container);

  container.appendChild(renderer.domElement);

  const { camera, controls } = initControls(renderer);

  window.addEventListener(
    "resize",
    createOnWindowResize(container, camera, renderer),
    false
  );

  return loadGlobal()
    .then(scene => {
      renderer.render(scene, camera);

      camera.position.set(...INITIAL_POSITION);

      return function animate() {
        requestAnimationFrame(animate);

        controls.update(); // only required if controls.enableDamping = true, or if controls.autoRotate = true
        renderer.render(scene, camera);
      };
    })
    .finally(() => onLoadingFinished(camera));
}
