import React, { useEffect, useState } from "react";
import { withCamera } from "../CameraContext";

const formatFloat = x => Number.parseFloat(x).toFixed(2);

export const CameraDebug = withCamera(function(props) {
  const [_, setTimer] = useState(null);
  useEffect(() => {
    const interval = setInterval(() => setTimer(Date.now()), 500);

    return () => clearInterval(interval);
  }, []);

  if (!props.camera) {
    return null;
  }

  const position = props.camera.position;

  return (
    <div className="absolute top-0 right-0 bg-gray white ma3 pa3">
      <ul className="list ma0 pa0">
        <li>{`x: ${formatFloat(position.x)}`}</li>
        <li>{`y: ${formatFloat(position.y)}`}</li>
        <li>{`z: ${formatFloat(position.z)}`}</li>
      </ul>
    </div>
  );
});
