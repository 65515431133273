var THREE = require("three");
import { MTLLoader, OBJLoader } from "three-obj-mtl-loader";

function loadMtl(url) {
  const [_, path, file] = url.match("(.*/)(.*)");
  const mtlLoader = new MTLLoader();
  mtlLoader.setMaterialOptions({ side: THREE.DoubleSide });
  mtlLoader.setPath(path);
  return new Promise((resolve, reject) => {
    mtlLoader.load(file, resolve, () => {}, reject);
  });
}

function loadObj(url, materials) {
  const [_, path, file] = url.match("(.*/)(.*)");
  const objLoader = new OBJLoader();
  objLoader.setPath(path);
  objLoader.setMaterials(materials);
  return new Promise((resolve, reject) => {
    objLoader.load(file, resolve, () => {}, reject);
  });
}

function initObject(objUrl, mtlUrl) {
  return loadMtl(mtlUrl)
    .then((materials) => {
      materials.preload();
      return materials;
    })
    .then((materials) => loadObj(objUrl, materials));
}

export function loadGlobal() {
  const scene = new THREE.Scene();
  scene.background = new THREE.Color("#C0DFEF");

  scene.add(new THREE.AmbientLight(0xffffff));

  return initObject("assets/global/CHATEAU.obj", "assets/global/CHATEAU.mtl")
    .then((object) => {
      object.position.set(50, 0, -150);
      scene.add(object);
    })
    .catch((error) => console.error(error))
    .then(() => scene);
}
