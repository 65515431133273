import React, { useState } from "react";

import { PanelContainer, NavBar } from "../../components/Panel";
import { withCamera } from "../CameraContext";

const IconButton = props => (
  <button
    className={`touch-button f4 link pointer fa ${
      props.iconName
    } bg-light-gray black pa2 self-center`}
    onClick={props.onClick}
  />
);

IconButton.defaultProps = {
  iconName: "fa-eye",
  onClick: () => {}
};

const ListItem = props => (
  <li
    className={`flex ba b--gray bl-0 bt-0 br-0 ph3 pv2 ${props.className ||
      ""}`}
  >
    <p className="flex-auto">{props.label}</p>
    {props.button}
  </li>
);

const CameraButton = withCamera(({ camera, position, onClick, ...props }) => (
  <IconButton
    {...props}
    onClick={() => {
      camera.position.set(...position);

      if (typeof onClick === "function") {
        onClick();
      }
    }}
  />
));

export const Items = props => (
  <PanelContainer>
    <div className="bg-primary white pa2">
      <p className="f4 mh3 tc">Visitez le Chasteau de Thiery.</p>
    </div>
    <ul className="list ma0 pa0">
      <ListItem label="Point de vues" className="bg-secondary white" />
      <ListItem
        label="Porte St Jean"
        button={<CameraButton iconName="fa-video" position={[66, 1.2, -185]} />}
      />
      <ListItem label="Visites 360" className="bg-secondary white" />
      <ListItem
        label="Crypte St Cénéric"
        button={
          <CameraButton
            iconName="fa-globe"
            position={[-77.32, 15.86, 57.07]}
            onClick={() => props.setArticle("crypte-st-ceneric")}
          />
        }
      />
      <ListItem
        label="Les Celliers"
        button={
          <CameraButton
            iconName="fa-globe"
            position={[-20.16, 15.74, 42.96]}
            onClick={() => props.setArticle("celliers")}
          />
        }
      />
      <ListItem
        label="Souterrain de la Dame Blanche"
        button={
          <CameraButton
            iconName="fa-globe"
            position={[22.7, 14.87, -136.81]}
            onClick={() => props.setArticle("souterrain-dame-blanche")}
          />
        }
      />
      <ListItem
        label="Les cuisines du château"
        button={
          <CameraButton
            iconName="fa-globe"
            position={[5.42, 67.64, 20.24]}
            onClick={() => props.setArticle("cuisines")}
          />
        }
      />
      <ListItem
        label="Quelques tours du château"
        className="bg-secondary white"
      />
      <ListItem
        label="Tour Bouillon"
        button={
          <CameraButton
            iconName="fa-info"
            position={[-58.53, 7.88, 210.71]}
            onClick={() => props.setArticle("tour-bouillon")}
          />
        }
      />
      <ListItem
        label="Tour du Roi"
        button={
          <CameraButton
            iconName="fa-info"
            position={[-85.41, 8.8, 228.22]}
            onClick={() => props.setArticle("tour-roi")}
          />
        }
      />
      <ListItem
        label="Tour Rouge"
        button={
          <CameraButton
            iconName="fa-info"
            position={[20, 17.92, 121.44]}
            onClick={() => props.setArticle("tour-rouge")}
          />
        }
      />
      <ListItem
        label="Tour Thibaud"
        button={
          <CameraButton
            iconName="fa-info"
            position={[-36.02, 37.35, -64.24]}
            onClick={() => props.setArticle("tour-thibault")}
          />
        }
      />
    </ul>
  </PanelContainer>
);

const TourBouillon = props => (
  <PanelContainer>
    <NavBar title="La Tour dite de Bouillon" goBack={props.goBack} />
    <img src="img/tour-bouillon.png" alt="La Tour Bouillon" />
    <div className="ph2">
      <p>
        La forme singulière de la Tour Bouillon est issue des nombreux
        remaniements successifs en rapport avec l’évolution de la résidence
        seigneuriale.
      </p>
      <p>
        Au XIII<sup>e</sup> siècle, il s’agit d’une simple tour défensive
        circulaire au 4/5 saillant comportant deux étages montés sur une salle
        basse.
      </p>
      <p>
        Au XIV<sup>e</sup> siècle, la création d’une deuxième ligne défensive ou
        «fausse-braie» nécessite l’aménagement d’un escalier en bois reliant les
        deux niveaux d’enceintes. Ce passage est dissimulé à l’arrière d’un mur
        disposé contre la tour.
      </p>
      <p>Une porte en tiers-point lui donne accès.</p>
      <p>
        Au XV<sup>e</sup> siècle, une tour-latrines rectangulaire est édifiée en
        liaison avec l’extension du logis seigneurial.
      </p>
      <p>
        Au XVI<sup>e</sup> siècle, les tours sont englobées pour former les
        soubassements d’un des pavillons de la «Galerie des Princes».
      </p>
    </div>
  </PanelContainer>
);

const TourThibault = props => (
  <PanelContainer>
    <NavBar title="La Tour Thibaud" goBack={props.goBack} />
    <img src="img/tour-thibaud.png" alt="La Tour Thibaud" />
    <div className="ph2">
      <p>
        Cette construction est, de très loin, l’ouvrage conservé le plus
        remarquable et le plus important de la place, tant d’un point de vue
        esthétique que scientifique. C’est le seul élément, encore en élévation
        sur le château, qui ne soit pas raccordé à l’enceinte. Il conserve une
        élévation d’environ cinq à six mètres. L’intérieur est presque
        totalement remblayé de terre.
      </p>
      <p>
        Par l’analyse des nombreux changements d’appareil, des formes
        différentes des ouvertures pratiquées il est possible de distinguer
        plusieurs phases de construction dans la constitution de cet ouvrage.
      </p>
      <h4>
        Phase 1 : X<sup>e</sup> - XI<sup>e</sup> siècle.
      </h4>
      <p>
        Un bâtiment rectangulaire de 14 x 12 m, réalisé en grand appareil
        possédant au moins une porte sur son côté nord. Ce bâtiment pourrait
        être interprété comme l’aula et la camera du Castellum Theodorici des
        Comtes de Vermandois.
      </p>
      <h4>
        Phase 2 : XII<sup>e</sup> siècle.
      </h4>
      <p>
        Extension du bâtiment au sud. Ajout d’une porte à double enroulement sur
        la face est.
      </p>
      <h4>
        Phase 3 : XIII<sup>e</sup> et XIV<sup>e</sup> siècle.
      </h4>
      <p>Une chapelle est adossée au mur ouest.</p>
      <h4>
        Phase 4 : fin XV<sup>e</sup> et début XVI<sup>e</sup> siècle.
      </h4>
      <p>
        Constitution du Fossé Sec au devant de l’édifice. Arasement des étages
        et implantation de la galerie sud utilisée pour le stockage de la poudre
        et des munitions. La partie arasée est entièrement remblayée de terre.
      </p>
    </div>
  </PanelContainer>
);

const TourRouge = props => (
  <PanelContainer>
    <NavBar title="La Tour Rouge" goBack={props.goBack} />
    <div className="ph2">
      <p>
        La Tour Rouge est de plan carré et constitue certainement un des
        ouvrages les plus intéressants de l’enceinte du château.
      </p>
      <p>
        D’apparence simple, elle dissimule en fait, une tour-porte monumentale
        créée au XIII<sup>e</sup> siècle, axe principal de communication entre
        la ville et le château.
      </p>
      <p>
        L’accès se faisait par la tour-porte en suivant une rampe à grands
        degrés couverte qui permettait aux charrois de gravir la forte
        dénivellation du bas de la porte à l’intérieur du château. En 1406,
        l’extension du logis seigneurial entraîne la suppression de ce passage.
      </p>
      <p>
        Une tour pourvue d’un escalier à vis en retrait est alors disposé
        devant.
      </p>
    </div>
  </PanelContainer>
);

const TourRoi = props => (
  <PanelContainer>
    <NavBar title="La Tour du Roi" goBack={props.goBack} />
    <div className="ph2">
      <p>
        La tour du Roi est une des tours circulaires du château, celle qui
        présente le plus fort diamètre.
      </p>
      <p>
        Cette tour de flanquement diffère des autres par la qualité de
        traitement du premier étage. Bien que les voûtes d’ogives aient
        disparue, on peut y voir un banc circulaire ou cousiège qui s’ouvre sur
        des archères régulièrement réparties. Sous cet espace s’étend une salle
        basse accessible par une ouverture rectangulaire disposée au sommet de
        la voûte en coupole au centre de la pièce.
      </p>
      <p>
        De construction soignée, les maçonneries extérieures de la tour sont
        parementées en carreaux de grès réguliers et l’intérieur entièrement
        réalisé en pierre de taille calcaire.
      </p>
      <p>
        La construction du grand logis seigneurial au XIII<sup>e</sup> siècle,
        connu sous le nom de «Galerie des Princes» explique le soin tout
        particulier apporté à cet ouvrage.
      </p>
    </div>
  </PanelContainer>
);

const CrypteStCeneric = props => (
  <PanelContainer>
    <NavBar title="Crypte St Cénéric" goBack={props.goBack} />
    <div className="ph2">
      <p>Visitez la crypte en 360°.</p>
      <button
        className="db w-100 pa2 bg-secondary white pointer"
        onClick={props.showVRScene}
      >
        Entrez dans la crypte
      </button>
    </div>
  </PanelContainer>
);

const SouterrainDameBlanche = props => (
  <PanelContainer>
    <NavBar title="Souterrain de la Dame Blanche" goBack={props.goBack} />
    <div className="ph2">
      <p>Visitez le souterrain en 360°.</p>
      <button
        className="db w-100 pa2 bg-secondary white pointer"
        onClick={props.showVRScene}
      >
        Entrez dans le souterrain
      </button>
    </div>
  </PanelContainer>
);

const Cuisines = props => (
  <PanelContainer>
    <NavBar title="Cuisines du château" goBack={props.goBack} />
    <div className="ph2">
      <p>Visitez les cuisines du château en 360°.</p>
      <button
        className="db w-100 pa2 bg-secondary white pointer"
        onClick={props.showVRScene}
      >
        Entrez dans les cuisines
      </button>
    </div>
  </PanelContainer>
);

const Celliers = props => (
  <PanelContainer>
    <NavBar title="Les Celliers" goBack={props.goBack} />
    <div className="ph2">
      <p>Visitez les celliers en 360°.</p>
      <button
        className="db w-100 pa2 bg-secondary white pointer"
        onClick={props.showVRScene}
      >
        Entrez dans les celliers
      </button>
    </div>
  </PanelContainer>
);

export const Panel = function(props) {
  const [article, setArticle] = useState(null);

  switch (article) {
    case "tour-bouillon":
      return <TourBouillon goBack={() => setArticle(null)} />;
    case "tour-rouge":
      return <TourRouge goBack={() => setArticle(null)} />;
      return <TourBouillon goBack={() => setArticle(null)} />;
    case "tour-roi":
      return <TourRoi goBack={() => setArticle(null)} />;
    case "tour-thibault":
      return <TourThibault goBack={() => setArticle(null)} />;
    case "crypte-st-ceneric":
      return (
        <CrypteStCeneric
          showVRScene={() => window.open("/crypt.html", "_blank")}
          goBack={() => setArticle(null)}
        />
      );
    case "souterrain-dame-blanche":
      return (
        <SouterrainDameBlanche
          showVRScene={() => window.open("/underground.html", "_blank")}
          goBack={() => setArticle(null)}
        />
      );
    case "cuisines":
      return (
        <Cuisines
          showVRScene={() => window.open("/cuisines.html", "_blank")}
          goBack={() => setArticle(null)}
        />
      );
    case "celliers":
      return (
        <Celliers
          showVRScene={() => window.open("/cellar.html", "_blank")}
          goBack={() => setArticle(null)}
        />
      );
    default:
      return <Items setArticle={setArticle} />;
  }
};
