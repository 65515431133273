import React, { useCallback, useContext } from "react";
import { init } from "./initThreeJS";

import { CameraContext } from "../CameraContext";
import { Legend } from "./Legend";

export { INITIAL_POSITION } from "./initThreeJS";

export function Castle() {
  const { setCamera } = useContext(CameraContext);
  const containerRef = useCallback((container) => {
    if (container !== null) {
      init(container, setCamera).then((animate) => animate());
    }
  }, []);

  return (
    <div className="relative vh-100 flex-auto">
      <div className="h-100 w-100" ref={containerRef}></div>
      <Legend />
    </div>
  );
}
