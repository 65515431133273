import React from "react";

function LegendRow({ imageSource, label }) {
  return (
    <div className="flex items-center justify-between pv1">
      <img src={imageSource} className="mr3" style={{ width: "32px" }} />
      {label}
    </div>
  );
}

export function Legend() {
  return (
    <div className="absolute bg-white bottom-0 left-0 ma3 ph3 pv1">
      <LegendRow imageSource={require("./visible.png")} label="Visible" />
      <LegendRow imageSource={require("./restitue.png")} label="Restitué" />
    </div>
  );
}
